import { apiUrl } from "@/env";
import axios from "axios";

import {
  IBillingInformation,
  ICard,
  IContinueWithPayedInferences,
  IDataset,
  IDatasetCreate,
  IDatasetUpdate,
  IDatasetUpdatePrompts,
  IInferencePromptCreate,
  IInferencePromptUpdate,
  IFreePlanCreate,
  IItem,
  ILabelContainerCreate,
  ILabelContainerUpdate,
  ILabelContainerUpdateUtteranceSpan,
  ILabelCreate,
  ILimitInferences,
  IMetrics,
  IModel,
  IModelCreate,
  IPaidPlanCreate,
  IPredictions,
  IPreviewParametersCategoryDate,
  IPreviewParametersSubCategories,
  IRecommendation,
  IRecommendationUpdate,
  ISubscription,
  IUserProfile,
  IUserProfileCreate,
  IUserProfileUpdate,
  IVATNumber,
  IWorkspaceCreate,
  IWorkspaceUserInvite,
  IDatasetUpdateRetention,
  IGenerativeModel,
  IGenerativeModelPromptUpdate,
  IGenerativeModelCreate,
  IEmbeddedDashboardCreate,
} from "./interfaces";
import { el, is } from "date-fns/locale";

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
function authHeadersBlob(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  };
}

function apiHeader(id: string, secret: string) {
  return {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      Authorization: "Basic " + id + ":" + secret,
    },
  };
}

function authFileHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async logInGetTokenSharedModels(id: string) {
    const params = new FormData();
    params.append("id", id);
    return axios.post(`${apiUrl}/api/v1/shared_models/access-token`, params);
  },
  async register(full_name: string, email: string, password: string) {
    return axios.post(`${apiUrl}/api/v1/users/signup`, {
      full_name,
      email,
      password,
    });
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate, workspaceId: number) {
    return axios.post(`${apiUrl}/api/v1/users/${workspaceId}`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string, name: string | null) {
    return axios.post(`${apiUrl}/api/v1/reset-password`, {
      new_password: password,
      token,
      name,
    });
  },
  async createWorkspace(token: string, data: IWorkspaceCreate) {
    return axios.post(`${apiUrl}/api/v1/workspaces`, data, authHeaders(token));
  },
  async getWorkspace(token: string, workspaceId: number) {
    return axios.get(`${apiUrl}/api/v1/workspaces/${workspaceId}`, authHeaders(token));
  },
  async updateWorkspace(token: string, workspaceId: number, data: IWorkspaceCreate) {
    return axios.put(`${apiUrl}/api/v1/workspaces/${workspaceId}`, data, authHeaders(token));
  },
  async InviteUserToWorkspace(token: string, workspaceId: number, data: IWorkspaceUserInvite) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/invite`,
      data,
      authHeaders(token),
    );
  },
  async updateUserWorkspaceRole(
    token: string,
    workspaceId: number,
    userId: number,
    newRole: string,
  ) {
    const formData = new FormData();
    formData.append("role", newRole);
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/user/${userId}/change_role`,
      formData,
      authHeaders(token),
    );
  },
  async RemoveUserFromWorkspace(token: string, workspaceId: number, userId: number) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/user/${userId}/remove`,
      {},
      authHeaders(token),
    );
  },

  async updateModel(token: string, workspaceId: number, modelId: number, data: IModelCreate) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}`,
      data,
      authHeaders(token),
    );
  },
  async updateGenerativeModel(
    token: string,
    workspaceId: number,
    modelId: number,
    data: IGenerativeModelCreate,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}`,
      data,
      authHeaders(token),
    );
  },
  async shareModel(token: string, workspaceId: number, modelId: number) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/share`,
      {},
      authHeaders(token),
    );
  },
  async unshareModel(token: string, workspaceId: number, modelId: number) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/share`,
      {},
      authHeaders(token),
    );
  },

  async uploadModel(token: string, workspaceId: number, file: File, config: any) {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(`${apiUrl}/api/v1/workspaces/${workspaceId}/models/upload`, formData, {
      headers: authFileHeaders(token).headers,
      onUploadProgress: config.onUploadProgress,
    });
  },

  async createDataset(
    token: string,
    workspaceId: number,
    data: IDatasetCreate,
    toggleHeader: boolean,
    isTranscription: boolean = false,
    isGenerative: boolean = false,
  ) {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("filename", data.filename);
    formData.append("toggle_header", toggleHeader.toString());

    if (isTranscription) {
      formData.append("audio_file_column_id", data.text_column_id.toString());
      return axios.post(
        `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/start_transcription`,
        formData,
        authFileHeaders(token),
      );
    } else if (isGenerative) {
      formData.append("text_column_id", "0");
      return axios.post(
        `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/create_generative_dataset`,
        formData,
        authFileHeaders(token),
      );
    }

    formData.append("text_column_id", data.text_column_id.toString());
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/create_dataset`,
      formData,
      authFileHeaders(token),
    );
  },

  async uploadDataset(token: string, workspaceId: number, file: File, config: any) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post(`${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/upload`, formData, {
      headers: authFileHeaders(token).headers,
      onUploadProgress: config.onUploadProgress,
    });
  },

  async refillDataset(
    token: string,
    workspaceId: number,
    datasetId: number,
    file: File,
    config: any,
  ) {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/refill`,
      formData,
      {
        headers: authFileHeaders(token).headers,
        onUploadProgress: config.onUploadProgress,
      },
    );
  },

  async createModelFromUpload(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelFilters: any[] | boolean,
    data: IModelCreate,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/create/${datasetId}?name=${data.name}&description=${data.description}`,
      modelFilters,
      authHeaders(token),
    );
  },

  async createModelFromUploadWithCustomLabels(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelFilters: any[] | boolean,
    labels: any,
    data: IModelCreate,
    taskDescription: string,
    confidenceThreshold: number,
    numItemsToLabel: number,
  ) {
    let requestBody = {
      labels: labels,
      model_filters: modelFilters,
    };
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/create_with_custom_labels/${datasetId}?name=${data.name}&description=${data.description}&task_description=${taskDescription}&confidence_threshold=${confidenceThreshold}&num_items_to_label=${numItemsToLabel}`,
      requestBody,
      authHeaders(token),
    );
  },

  async createGenerativeModelFromUpload(
    token: string,
    workspaceId: number,
    datasetId: number,
    algorithm: string,
    data: IModelCreate,
    modelFilters: any[] | boolean,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/create_generative/${datasetId}?name=${data.name}&algorithm=${algorithm}&description=${data.description}`,
      modelFilters,
      authHeaders(token),
    );
  },

  async startLLMTraining(token: string, workspaceId: number, modelId: number) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}/start_llm_training`,
      {},
      authHeaders(token),
    );
  },

  async createModelFromUploadWithLabels(
    token: string,
    workspaceId: number,
    datasetId: number,
    columnIdLabel: number,
    multiLabel: boolean,
    valSetPercentage: number,
    modelFilters: any[] | boolean,
    data: IModelCreate,
    labelManager: any,
  ) {
    let requestBody = {
      manage_labels: labelManager,
      model_filters: modelFilters,
    };
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/create_with_labels/${datasetId}?name=${data.name}&description=${data.description}&column_id_label=${columnIdLabel}&multi_label=${multiLabel}&val_set_percentage=${valSetPercentage}`,
      requestBody,
      authHeaders(token),
    );
  },
  async labelPreview(
    token: string,
    workspaceId: number,
    datasetId: number,
    columnIdLabel: number,
    multiLabel: boolean,
    modelFilters: any[] | boolean,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/label_preview/${datasetId}?column_id_label=${columnIdLabel}&multi_label=${multiLabel}`,
      modelFilters,
      authHeaders(token),
    );
  },

  async deleteModel(token: string, workspaceId: number, modelId: number) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}`,
      authHeaders(token),
    );
  },

  async deleteGenerativeModel(token: string, workspaceId: number, modelId: number) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}`,
      authHeaders(token),
    );
  },

  async resetModel(token: string, workspaceId: number, modelId: number) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/reset`,
      authHeaders(token),
    );
  },
  async getModels(token: string, workspaceId: number) {
    return axios.get<IModel[]>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models`,
      authHeaders(token),
    );
  },
  async getGenerativeModels(token: string, workspaceId: number) {
    return axios.get<IGenerativeModel[]>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models`,
      authHeaders(token),
    );
  },
  async getModel(token: string, workspaceId: number, modelId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}`,
      authHeaders(token),
    );
  },
  async getConnectedDatasets(token: string, workspaceId: number, modelId: number) {
    return axios.get<IDataset[]>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/connected_datasets`,
      authHeaders(token),
    );
  },

  async getGenerativeModelConnectedDatasets(token: string, workspaceId: number, modelId: number) {
    return axios.get<IDataset[]>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/generative_model_connected_datasets`,
      authHeaders(token),
    );
  },

  async getDatasets(token: string, workspaceId: number) {
    return axios.get<IDataset[]>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets`,
      authHeaders(token),
    );
  },
  async getDataset(token: string, workspaceId: number, datasetId: number) {
    return axios.get<IDataset>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}`,
      authHeaders(token),
    );
  },
  async getDatasetConnectedModels(token: string, workspaceId: number, datasetId: number) {
    return axios.get<IModel[]>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/connected_models`,
      authHeaders(token),
    );
  },
  async getDatasetConnectedGenerativeModels(token: string, workspaceId: number, datasetId: number) {
    return axios.get<IGenerativeModel[]>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/connected_generative_models`,
      authHeaders(token),
    );
  },
  async getDatasetConnectedModelsWithLinks(token: string, workspaceId: number, datasetId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/connected_models_with_links`,
      authHeaders(token),
    );
  },
  async getAllConnectedModelsWithLinks(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/connected_models_with_links`,
      authHeaders(token),
    );
  },

  async getDatasetPreview(
    token: string,
    workspaceId: number,
    datasetId: number,
    extraColumns: boolean = false,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/preview?extra_columns=${extraColumns}`,
      authHeaders(token),
    );
  },
  async updateDataset(
    token: string,
    workspaceId: number,
    datasetId: number,
    dataset: IDatasetUpdate,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}`,
      dataset,
      authHeaders(token),
    );
  },
  async updateDatasetPrompts(
    token: string,
    workspaceId: number,
    datasetId: number,
    datasetPrompts: IDatasetUpdatePrompts,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/prompts`,
      datasetPrompts,
      authHeaders(token),
    );
  },
  async updateDatasetRetention(
    token: string,
    workspaceId: number,
    datasetId: number,
    datasetRetention: IDatasetUpdateRetention,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/retention`,
      datasetRetention,
      authHeaders(token),
    );
  },
  async deleteDataset(token: string, workspaceId: number, datasetId: number) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}`,
      authHeaders(token),
    );
  },

  async createLabelContainer(token: string, modelId: number, data: ILabelContainerCreate) {
    return axios.post(
      `${apiUrl}/api/v1/models/${modelId}/label_containers`,
      data,
      authHeaders(token),
    );
  },
  async updateLabelContainer(
    token: string,
    modelId: number,
    labelContainerId: number,
    data: ILabelContainerUpdate,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/models/${modelId}/label_containers/${labelContainerId}`,
      data,
      authHeaders(token),
    );
  },
  async updateLabelContainerUtteranceSpan(
    token: string,
    modelId: number,
    labelContainerId: number,
    data: ILabelContainerUpdateUtteranceSpan,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/models/${modelId}/label_containers/${labelContainerId}/utterance_span`,
      data,
      authHeaders(token),
    );
  },
  async createLabel(token: string, labelContainerId: number, data: ILabelCreate) {
    return axios.post(
      `${apiUrl}/api/v1/label_containers/${labelContainerId}/labels`,
      data,
      authHeaders(token),
    );
  },
  async createLabelExample(token: string, labelContainerId: number, labelId: number, data: string) {
    return axios.post(
      `${apiUrl}/api/v1/label_containers/${labelContainerId}/labels/${labelId}/examples`,
      [data],
      authHeaders(token),
    );
  },
  async updateLabel(token: string, labelContainerId: number, labelId: number, data: ILabelCreate) {
    return axios.put(
      `${apiUrl}/api/v1/label_containers/${labelContainerId}/labels/${labelId}`,
      data,
      authHeaders(token),
    );
  },
  async deleteLabel(token: string, labelContainerId: number, labelId: number) {
    return axios.delete(
      `${apiUrl}/api/v1/label_containers/${labelContainerId}/labels/${labelId}`,
      authHeaders(token),
    );
  },
  async getItem(token: string, modelId: number) {
    return axios.get<IItem[]>(
      `${apiUrl}/api/v1/models/${modelId}/recommendation`,
      authHeaders(token),
    );
  },

  async getRecommendation(
    token: string,
    modelId: number,
    languageFilter: string,
    lastId: string | null,
  ) {
    return axios.get<IRecommendation>(
      `${apiUrl}/api/v1/models/${modelId}/recommendation?language_filter=${languageFilter}&last_id=${lastId}`,
      authHeaders(token),
    );
  },
  async getValidationRecommendation(token: string, modelId: number) {
    return axios.get<IRecommendation>(
      `${apiUrl}/api/v1/models/${modelId}/recommendation/validation`,
      authHeaders(token),
    );
  },
  async getConfusionMatrixItems(
    token: string,
    modelId: number,
    actualLabelId: number,
    predictedLabelId: number,
    threshold: number,
    train: boolean,
  ) {
    return axios.get<IItem[]>(
      `${apiUrl}/api/v1/models/${modelId}/items/validation?user_label=${actualLabelId}&predicted_label=${predictedLabelId}&threshold=${threshold}&train=${train}`,
      authHeaders(token),
    );
  },
  async getPredictedItems(
    token: string,
    modelId: number,
    predictedLabelId: number,
    threshold: number,
  ) {
    return axios.get<IItem[]>(
      `${apiUrl}/api/v1/models/${modelId}/items/predicted?predicted_label=${predictedLabelId}&threshold=${threshold}`,
      authHeaders(token),
    );
  },

  async postItemLabel(token: string, modelId: number, data: IRecommendationUpdate) {
    return axios.put(
      `${apiUrl}/api/v1/models/${modelId}/items/user_labels`,
      data,
      authHeaders(token),
    );
  },

  async getPredictions(token: string, modelId: number, threshold: number) {
    return axios.get<IPredictions[]>(
      `${apiUrl}/api/v1/models/${modelId}/aggregated_predictions?threshold=${threshold}`,
      authHeaders(token),
    );
  },

  async getAccuracy(token: string, modelId: number) {
    return axios.get<number>(`${apiUrl}/api/v1/models/${modelId}/accuracy`, authHeaders(token));
  },

  async getPredictionItems(
    token: string,
    modelId: number,
    labelContainerId: number,
    labelId: number,
  ) {
    return axios.get<IItem[]>(
      `${apiUrl}/api/v1/models/${modelId}/predictions?label_container_id=${labelContainerId}&label_id=${labelId}&threshold=0.98`,
      authHeaders(token),
    );
  },

  async getLast(token: string, modelId: number) {
    return axios.get<IRecommendation>(
      `${apiUrl}/api/v1/models/${modelId}/items/regret`,
      authHeaders(token),
    );
  },

  async getMetrics(token: string, modelId: number, threshold: number, train: boolean) {
    return axios.get<IMetrics>(
      `${apiUrl}/api/v1/models/${modelId}/performance_metrics?threshold=${threshold}&train=${train}`,
      authHeaders(token),
    );
  },

  // async getExport(token: string, workspaceId: number, datasetId: number, modelId: number, threshold: number, config: any) {
  //   return axios.get(`${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/export/${modelId}?threshold=${threshold}`, {headers: authHeadersBlob(token).headers, responseType:authHeadersBlob(token).responseType, onDownloadProgress: config.onDownloadrogress});
  // },
  async getAllDatasetExport(token: string, workspaceId: number, datasetId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/dataset_export`,
      authHeaders(token),
    );
  },

  async getAllRefill(token: string, workspaceId: number, datasetId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/refill`,
      authHeaders(token),
    );
  },

  async getDatasetExport(
    token: string,
    workspaceId: number,
    datasetId: number,
    datasetExportId: number,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/dataset_export/${datasetExportId}`,
      authHeaders(token),
    );
  },

  async downloadDatasetExport(
    token: string,
    workspaceId: number,
    datasetId: number,
    datasetExportId: number,
    config: any,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/dataset_export/${datasetExportId}/download`,
      {
        headers: authHeadersBlob(token).headers,
        responseType: authHeadersBlob(token).responseType,
        onDownloadProgress: config.onDownloadrogress,
      },
    );
  },
  async downloadWer(token: string, workspaceId: number, datasetId: number, config: any) {
    return axios.get(`${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/wer-data`, {
      headers: authHeadersBlob(token).headers,
      responseType: authHeadersBlob(token).responseType,
      onDownloadProgress: config.onDownloadrogress,
    });
  },

  async downloadRefill(
    token: string,
    workspaceId: number,
    datasetId: number,
    refillId: number,
    config: any,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/refill/${refillId}/download`,
      {
        headers: authHeadersBlob(token).headers,
        responseType: authHeadersBlob(token).responseType,
        onDownloadProgress: config.onDownloadrogress,
      },
    );
  },

  async prepareDatasetExport(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    threshold: number,
  ) {
    const data = new FormData();
    data.append("model_id", modelId.toString());
    data.append("threshold", threshold.toString());

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/dataset_export`,
      data,
      authHeaders(token),
    );
  },
  async prepareDatasetExportAllDeployed(
    token: string,
    workspaceId: number,
    datasetId: number,
    threshold: number,
  ) {
    const data = new FormData();
    data.append("threshold", threshold.toString());

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/full_dataset_export`,
      data,
      authHeaders(token),
    );
  },

  async exportAllDeployedConversation(token: string, workspaceId: number, datasetId: number) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/full_dataset_conversation_export`,
      {},
      authHeaders(token),
    );
  },

  async prepareColumnDatasetExport(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    columnId: number,
  ) {
    const data = new FormData();
    data.append("model_id", modelId.toString());
    data.append("column_id", columnId.toString());

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/inference_on_column`,
      data,
      authHeaders(token),
    );
  },

  async deleteDatasetExport(
    token: string,
    workspaceId: number,
    datasetId: number,
    datasetExportId: number,
  ) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/dataset_export/${datasetExportId}`,
      authHeaders(token),
    );
  },
  //===========================

  async getOriginal(token: string, workspaceId: number, datasetId: number, config: any) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/download_original`,
      {
        headers: authHeadersBlob(token).headers,
        responseType: authHeadersBlob(token).responseType,
        onDownloadProgress: config.onDownloadrogress,
      },
    );
  },
  async getInternal(token: string, workspaceId: number, modelId: number, config: any) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/internal-dataset/download`,
      {
        headers: authHeadersBlob(token).headers,
        responseType: authHeadersBlob(token).responseType,
        onDownloadProgress: config.onDownloadrogress,
      },
    );
  },

  async getOnlyLabeled(token: string, modelId: number) {
    return axios.get(`${apiUrl}/api/v1/utils/download-labeled/${modelId}`, {
      headers: authHeadersBlob(token).headers,
      responseType: authHeadersBlob(token).responseType,
    });
  },

  async getSupersetToken(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/embedded_dashboards/token`,
      authHeaders(token),
    );
  },

  async getEmbeddedDashboards(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/embedded_dashboards`,
      authHeaders(token),
    );
  },

  async getEmbeddedDashboard(token: string, workspaceId: number, embeddedId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/embedded_dashboards/embedded_dashboard/${embeddedId}`,
      authHeaders(token),
    );
  },

  async unpublishEmbeddedDashboard(token: string, workspaceId: number, embeddedId: number) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/embedded_dashboards/embedded_dashboard/${embeddedId}/unpublish`,
      authHeaders(token),
    );
  },

  async embedDashboard(token: string, workspaceId: number, obj: IEmbeddedDashboardCreate) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/embedded_dashboards`,
      obj,
      authHeaders(token),
    );
  },

  async getUnpublishedDahsboards(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/embedded_dashboards/unpublished`,
      authHeaders(token),
    );
  },

  async getExportPrice(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    threshold: number,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/export/${modelId}/price?threshold=${threshold}`,
      authHeaders(token),
    );
  },

  async createClientCredentials(token: string, workspaceId: number) {
    return axios.post<any>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/api_user`,
      [],
      authHeaders(token),
    );
  },

  async revokeClientCredentials(token: string, workspaceId: number, client_name: string) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/clients/${client_name}`,
      authHeaders(token),
    );
  },
  async createBearerToken(token: string, id: string, secret: string) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("secret", secret);

    return axios.post<any>(`${apiUrl}/api/v1/users/bearer_token`, formData, authHeaders(token));
  },

  async createClientAndToken(token: string, workspaceId: number) {
    return axios.post<any>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/create_api_token`,
      [],
      authHeaders(token),
    );
  },
  async getClients(token: string, workspaceId: number) {
    return axios.get<any>(`${apiUrl}/api/v1/workspaces/${workspaceId}/clients`, authHeaders(token));
  },

  async getModelsOlds(token: string, workspaceId: number) {
    return axios.get<any>(`${apiUrl}/api/v1/workspaces/${workspaceId}/models`, authHeaders(token));
  },

  async getModelsByModelId(token: string, workspaceId: number, modelId: number) {
    return axios.get<any>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/models/${modelId}`,
      authHeaders(token),
    );
  },
  // SUBSCRIPTIONS & BILLING BELOW
  async updateSubscription(token: string, workspaceId: number, plan: string) {
    const formData = new FormData();
    formData.append("plan", plan);
    return axios.put<ISubscription>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/subscription`,
      formData,
      authHeaders(token),
    );
  },
  async createSubscription(token: string, workspaceId: number, plan: string) {
    const formData = new FormData();
    formData.append("plan", plan);
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/subscription`,
      formData,
      authHeaders(token),
    );
  },
  async addDefaultPaymentMethod(token: string, workspaceId: number, paymentMethodId: string) {
    const formData = new FormData();
    formData.append("payment_method_id", paymentMethodId);
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/billing/card`,
      formData,
      authHeaders(token),
    );
  },
  async getDefaultPaymentMethod(token: string, workspaceId: number) {
    return axios.get<ICard>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/billing/card`,
      authHeaders(token),
    );
  },
  async getSubscription(token: string, workspaceId: number) {
    return axios.get<ISubscription>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/subscription`,
      authHeaders(token),
    );
  },
  async getNextInvoice(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/billing/comming_invoice`,
      authHeaders(token),
    );
  },
  async getFinishedInvoices(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/billing/invoice`,
      authHeaders(token),
    );
  },
  async cancelSubscription(token: string, workspaceId: number) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/subscription`,
      authHeaders(token),
    );
  },
  async updateBillingInformation(token: string, workspaceId: number, data: IBillingInformation) {
    return axios.put<IBillingInformation>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/billing`,
      data,
      authHeaders(token),
    );
  },
  async getBillingInformation(token: string, workspaceId: number) {
    return axios.get<IBillingInformation>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/billing`,
      authHeaders(token),
    );
  },
  async getVAT(token: string, workspaceId: number) {
    return axios.get(`${apiUrl}/api/v1/workspaces/${workspaceId}/billing/VAT`, authHeaders(token));
  },
  async updateVAT(token: string, workspaceId: number, data: IVATNumber) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/billing/VAT`,
      data,
      authHeaders(token),
    );
  },
  async continueWithPayedInferences(
    token: string,
    workspaceId: number,
    data: IContinueWithPayedInferences,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/subscription/limit`,
      data,
      authHeaders(token),
    );
  },
  async limitInferences(token: string, workspaceId: number, formData: ILimitInferences) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/subscription/user_set_limit`,
      formData,
      authHeaders(token),
    );
  },
  async getPlans(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/subscription/plans`,
      authHeaders(token),
    );
  },
  async getAllPlans(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/subscription/plans/all`,
      authHeaders(token),
    );
  },
  async getInferenceAproximation(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/subscription/inference_cost_aproximation`,
      authHeaders(token),
    );
  },
  async getLastPaymentStatus(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/billing/last_payment_status`,
      authHeaders(token),
    );
  },
  async getFailedPaymentStatus(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/billing/failed_payment_status`,
      authHeaders(token),
    );
  },
  //FOR ADMIN PAGE
  async getAdminSubscriptionPlans(token: string) {
    return axios.get(`${apiUrl}/api/v1/subscription_plans/all`, authHeaders(token));
  },
  async getUserByEmail(token: string, email: string) {
    return axios.get(`${apiUrl}/api/v1/users/email/${email}`, authHeaders(token));
  },
  async createFreeSubscriptionPlan(token: string, data: IFreePlanCreate) {
    return axios.post(`${apiUrl}/api/v1/subscription_plans/free`, data, authHeaders(token));
  },
  async createPaidSubscriptionPlan(token: string, data: IPaidPlanCreate) {
    return axios.post(`${apiUrl}/api/v1/subscription_plans/paid`, data, authHeaders(token));
  },
  async getPrivatePlansByWorkspace(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/subscription_plans/workspace/${workspaceId}/private_plans`,
      authHeaders(token),
    );
  },
  async updateWorkspaceSubscriptionPlan(token: string, planId: number, workspaceId: number) {
    const formData = new FormData();
    formData.append("plan_id", planId.toString());
    formData.append("workspace_id", workspaceId.toString());

    return axios.put(
      `${apiUrl}/api/v1/subscription_plans/${planId}/workspace/${workspaceId}/update`,
      formData,
      authHeaders(token),
    );
  },
  async AddPrivatePlanToWorkspace(token: string, planId: number, workspaceId: number) {
    const formData = new FormData();
    formData.append("plan_id", planId.toString());
    formData.append("workspace_id", workspaceId.toString());

    return axios.post(
      `${apiUrl}/api/v1/subscription_plans/${planId}/workspace/${workspaceId}/private_plan`,
      formData,
      authHeaders(token),
    );
  },
  async RemovePrivatePlanFromWorkspace(token: string, planId: number, workspaceId: number) {
    return axios.delete(
      `${apiUrl}/api/v1/subscription_plans/${planId}/workspace/${workspaceId}/private_plan`,
      authHeaders(token),
    );
  },

  async getAllSubscribedWorkspaces(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/subscription/all`,
      authHeaders(token),
    );
  },

  async getAllIntegrations(token: string, workspaceId: number) {
    return axios.get(`${apiUrl}/api/v1/workspaces/${workspaceId}/integrations`, authHeaders(token));
  },
  async plainTextSearchRecommendation(token: string, modelId: number, plainText: string) {
    return axios.get<IRecommendation>(
      `${apiUrl}/api/v1/models/${modelId}/items/search?search_string=${plainText}`,
      authHeaders(token),
    );
  },
  async testModelInference(token: string, workspaceId: number, modelId: number, text: string) {
    const formData = new FormData();
    formData.append("text", text);

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/test`,
      formData,
      authFileHeaders(token),
    );
  },

  async testGenerativeModelInference(
    token: string,
    workspaceId: number,
    modelId: number,
    text: string,
    system_prompt: string = "",
  ) {
    const url = `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/test_generative`;
    const headers = { ...authHeaders(token).headers };
    headers["Content-Type"] = "application/json";

    const body: string = JSON.stringify({
      messages: [
        { role: "system", content: system_prompt },
        { role: "user", content: text },
      ],
      model_id: modelId,
    });

    // Make the fetch request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    });

    // Check if the response status code is not 200 (OK)
    if (!response.ok) {
      // Throw an error with the status code and status text
      console.log(body);
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    // Check if the response has a body before returning
    if (!response.body) {
      throw new Error("No ReadableStream received");
    }

    // Return the reader for the body stream
    return response.body.getReader();
  },

  async callStreamingGenerativeModel(
    token: string,
    workspaceId: number,
    messages: any,
    modelId: number = -1,
    response_type: string = "text",
  ) {
    const url = `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/generative_request_stream`;
    const headers = { ...authHeaders(token).headers };
    headers["Content-Type"] = "application/json";

    const body: string = JSON.stringify({
      messages: messages,
      model_id: modelId,
      response_type: response_type,
    });

    // Make the fetch request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    });

    // Check if the response status code is not 200 (OK)
    if (!response.ok) {
      // Throw an error with the status code and status text
      console.log(body);
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    // Check if the response has a body before returning
    if (!response.body) {
      throw new Error("No ReadableStream received");
    }

    // Return the reader for the body stream
    return response.body.getReader();
  },

  async callGenerativeModel(
    token: string,
    workspaceId: number,
    messages: any,
    modelId: number = -1,
    response_type: string = "text",
  ) {
    const body = {
      messages: messages,
      model_id: modelId,
      response_type: response_type,
    };

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/generative_request`,
      body,
      authHeaders(token),
    );
  },


  async callGenerativeModelBatched(
    token: string,
    workspaceId: number,
    messages: any[],
    modelId: number = -1,
    response_type: string = "text",
  ) {
    const body = {
      messages: messages,
      model_id: modelId,
      response_type: response_type,
    };

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/generative_request_batched`,
      body,
      authHeaders(token),
    );
  },

  async getMultiLabelMetrics(
    token: string,
    modelId: number,
    threshold: number,
    labelId: number | null,
    train: boolean,
  ) {
    return axios.get<IMetrics>(
      `${apiUrl}/api/v1/models/${modelId}/performance_metrics_multi_label?threshold=${threshold}&label_id=${labelId}&train=${train}`,
      authHeaders(token),
    );
  },
  async getModelLanguages(token: string, workspaceId: number, modelId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/languages`,
      authHeaders(token),
    );
  },
  async getAvailableDatasets(token: string, workspaceId: number, modelId: number) {
    return axios.get<IDataset[]>(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/available_datasets`,
      authHeaders(token),
    );
  },
  async connectDataset(
    token: string,
    workspaceId: number,
    modelId: number,
    datasetId: number,
    modelFilters: any[] | boolean,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/dataset/${datasetId}`,
      modelFilters,
      authHeaders(token),
    );
  },
  async setRefillFrequency(
    token: string,
    workspaceId: number,
    datasetId: number,
    refillFrequency: string,
  ) {
    const formData = new FormData();
    formData.append("refill_frequency", refillFrequency);
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/refill_frequency`,
      formData,
      authHeaders(token),
    );
  },
  async toggleSound(token: string, workspaceId: number, datasetId: number) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/sound`,
      {},
      authHeaders(token),
    );
  },

  async setDsat(token: string, workspaceId: number, datasetId: number, dsat: number[]) {
    const formData = new FormData();
    let requestBody = {
      dsat: dsat,
    };
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/settings`,
      requestBody,
      authHeaders(token),
    );
  },

  async connectDatasetByol(
    token: string,
    workspaceId: number,
    modelId: number,
    datasetId: number,
    byolColumnId: number,
    modelFilters: any[] | boolean,
    labelManager: any,
  ) {
    let requestBody = {
      manage_labels: labelManager,
      model_filters: modelFilters,
    };

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/dataset/${datasetId}/addon_byol?column_id_label=${byolColumnId}`,
      requestBody,
      authHeaders(token),
    );
  },
  async disconnectDataset(token: string, workspaceId: number, modelId: number, datasetId: number) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/dataset/${datasetId}`,
      {},
      authHeaders(token),
    );
  },
  async copyModelToWorkspace(token: string, workspaceId: number, modelId: number) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/copy`,
      {},
      authHeaders(token),
    );
  },

  async copyModelToWorkspaceAdmin(token: string, copyModelId: number, copyWorkspaceId: number) {
    const formData = new FormData();
    formData.append("copy_model_id", copyModelId.toString());
    formData.append("copy_to_workspace_id", copyWorkspaceId.toString());

    return axios.post(`${apiUrl}/api/v1/utils/admincopy`, formData, authHeaders(token));
  },

  async clusterSetup(
    token: string,
    datasetId: number,
    hparams: any,
    clusterType: string,
    clusterKey: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/utils/dataset/${datasetId}/re-cluster?cluster_type=${clusterType}&cluster_key=${clusterKey}`,
      hparams,
      authHeaders(token),
    );
  },

  async renameClusters(token: string, datasetId: number, clusterType: string, clusterKey: string) {
    return axios.post(
      `${apiUrl}/api/v1/utils/dataset/${datasetId}/re-name-clusters?cluster_type=${clusterType}&cluster_key=${clusterKey}`,
      {},
      authHeaders(token),
    );
  },

  async censorDataset(token: string, datasetId: number) {
    return axios.post(`${apiUrl}/api/v1/utils/dataset/${datasetId}/censor`, {}, authHeaders(token));
  },

  async transformModelToSingle(token: string, copyModelId: number, copyWorkspaceId: number) {
    const formData = new FormData();
    formData.append("copy_model_id", copyModelId.toString());
    formData.append("copy_to_workspace_id", copyWorkspaceId.toString());

    return axios.post(
      `${apiUrl}/api/v1/utils/adminconvertmultitosingle`,
      formData,
      authHeaders(token),
    );
  },

  // ---------------------SHARED MODEL-------------------
  async testSharedModelInference(token: string, hash: string, text: string) {
    const formData = new FormData();
    formData.append("text", text);

    return axios.post(
      `${apiUrl}/api/v1/shared_models/${hash}/inference`,
      formData,
      authHeaders(token),
    );
  },
  async sharedLabels(token: string, hash: string) {
    return axios.get(`${apiUrl}/api/v1/shared_models/${hash}`, authHeaders(token));
  },
  // ---------------------SHARED MODEL-------------------
  async consent(token: string, consentChallenge: string, accept: boolean) {
    return axios.post(
      `${apiUrl}/api/v1/third_party/consent?consent_challenge=${consentChallenge}&accept=${accept}`,
      {},
      authHeaders(token),
    );
  },
  async pickWorkspace(token: string, loginChallenge: string, workspaceId: number, accept: boolean) {
    return axios.post(
      `${apiUrl}/api/v1/third_party/workspaces/${workspaceId}?login_challenge=${loginChallenge}&accept=${accept}`,
      {},
      authHeaders(token),
    );
  },
  async getClient(token: string, consentChallenge: string) {
    return axios.get(
      `${apiUrl}/api/v1/third_party/client/info?consent_challenge=${consentChallenge}`,
      authHeaders(token),
    );
  },
  async getThirdPartyConnections(token: string, workspaceId: number) {
    return axios.get(`${apiUrl}/api/v1/third_party/${workspaceId}`, authHeaders(token));
  },

  async removeThirdPartyAuth(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/third_party/revoke/${id}`, authHeaders(token));
  },

  async createClient(token: string, name: string, redirect: string, logo: string) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("redirect", redirect);
    formData.append("logo", logo);

    return axios.post(
      `${apiUrl}/api/v1/third_party/create_new_client`,
      formData,
      authHeaders(token),
    );
  },
  async getAlreadyConnectedWorkspaces(token: string, loginChallenge: string) {
    return axios.get(
      `${apiUrl}/api/v1/third_party/integrations/existing?login_challenge=${loginChallenge}`,
      authHeaders(token),
    );
  },
  async startSetup(token: string, datasetId: number, cluster: boolean) {
    const formData = new FormData();
    formData.append("dataset_id", datasetId.toString());
    formData.append("cluster", cluster.toString());

    return axios.post(`${apiUrl}/api/v1/utils/start-setup`, formData, authHeaders(token));
  },
  async startTraining(token: string, model_id: number, modelType: string) {
    const formData = new FormData();
    formData.append("model_id", model_id.toString());
    formData.append("type", modelType);

    return axios.post(`${apiUrl}/api/v1/utils/start-training`, formData, authHeaders(token));
  },

  async retrain(token: string, workspaceId: number, modelId: number) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/retrain`,
      {},
      authHeaders(token),
    );
  },

  async syntheticData(token: string, modelId: number, labelId: number) {
    const formData = new FormData();
    formData.append("label_id", labelId.toString());

    return axios.post(
      `${apiUrl}/api/v1/models/${modelId}/items/synthetic`,
      formData,
      authHeaders(token),
    );
  },
  async syntheticPromptData(token: string, modelId: number, prompt: string) {
    const formData = new FormData();
    formData.append("prompt", prompt);

    return axios.post(
      `${apiUrl}/api/v1/models/${modelId}/items/prompt_synthetic`,
      formData,
      authHeaders(token),
    );
  },
  async corrections(token: string, modelId: number, languageFilter: string) {
    return axios.post(
      `${apiUrl}/api/v1/models/${modelId}/items/corrections?language_filter=${languageFilter}`,
      {},
      authHeaders(token),
    );
  },
  async adminCheckStoreRows(token: string, datasetId: number) {
    return axios.get(`${apiUrl}/api/v1/utils/storeinfo/${datasetId}`, authHeaders(token));
  },

  async cloneIndexChangeSettings(token: string, datasetId: number, shardSize: number) {
    const formData = new FormData();
    formData.append("shard_size", shardSize.toString());
    formData.append("dataset_id", datasetId.toString());

    return axios.put(
      `${apiUrl}/api/v1/utils/cloneindexchangesettings`,
      formData,
      authHeaders(token),
    );
  },

  async finalizeTranscribeDataset(token: string, datasetId: number, audioFileColumnId: number) {
    return axios.get(
      `${apiUrl}/api/v1/utils/finalizetranscribe/${datasetId}?audio_file_column_id=${audioFileColumnId}`,
      authHeaders(token),
    );
  },

  async finalizeTranscribeDatasetRefill(token: string, refillId: number) {
    return axios.get(
      `${apiUrl}/api/v1/utils/finalizetranscriberefill/${refillId}`,
      authHeaders(token),
    );
  },

  async deleteDeletedLabels(token: string, modelId: number) {
    return axios.delete(`${apiUrl}/api/v1/utils/deletestorelabels/${modelId}`, authHeaders(token));
  },

  async inferenceOnNewData(token: string, modelId: number) {
    const formData = new FormData();
    formData.append("model_id", modelId.toString());

    return axios.post(`${apiUrl}/api/v1/utils/start-refill-deploy`, formData, authHeaders(token));
  },

  async adminClearStore(token: string) {
    return axios.post(`${apiUrl}/api/v1/utils/clearstore`, {}, authHeaders(token));
  },
  async adminGenerateLink(token: string, workspaceId: number, email: string) {
    const data = new FormData();
    data.append("email", email);
    data.append("workspace_id", workspaceId.toString());
    return axios.post(`${apiUrl}/api/v1/utils/admingeneratelink`, data, authHeaders(token));
  },
  async adminDownloadFile(token: string, datasetId: number) {
    return axios.post(`${apiUrl}/api/v1/utils/download/${datasetId}`, {}, authHeaders(token));
  },
  async getState(token: string, workspaceId: number, integrationType: string) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/state?integration_type=${integrationType}`,
      authHeaders(token),
    );
  },
  async clusterById(token: string, modelId: number, clusterId: number, searchString: string) {
    return axios.get(
      `${apiUrl}/api/v1/models/${modelId}/items/clusters/${clusterId}?search_string=${searchString}`,
      authHeaders(token),
    );
  },
  async clusterCount(token: string, workspaceId: number, modelId: number, searchString: string) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/clusters?search_string=${searchString}`,
      authHeaders(token),
    );
  },

  async callWhisper(token: string, audioBlob: Blob) {
    const formData = new FormData();
    formData.append("file", audioBlob, "audio_file.wav");
    return axios.post(`${apiUrl}/api/v1/utils/whisper`, formData, authHeaders(token));
  },

  async getInternalDataset(token: string, workspaceId: number, modelId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/internal-dataset`,
      authHeaders(token),
    );
  },
  async getInternalDatasetPreview(
    token: string,
    workspaceId: number,
    modelId: number,
    pageStart: number,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/internal-dataset/preview?page_start=${pageStart}`,
      authHeaders(token),
    );
  },
  async searchPlayStore(token: string, searchTerm: string) {
    return axios.get(
      `${apiUrl}/api/v1/utils/play-store-apps?search_term=${searchTerm}`,
      authHeaders(token),
    );
  },
  async searchAppStore(token: string, searchTerm: string) {
    return axios.get(
      `${apiUrl}/api/v1/utils/app-store-apps?search_term=${searchTerm}`,
      authHeaders(token),
    );
  },

  async getCSVGraph(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    column: any,
    minDate: any,
    maxDate: any,
    modelFilters: any[],
    dateBucketType: string,
    selectedDateZoomIn: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/csat?min_date=${minDate}&max_date=${maxDate}&column=${column}&date_bucket=${dateBucketType}&zoom_in=${selectedDateZoomIn}`,
      modelFilters,
      authHeaders(token),
    );
  },
  async getCSVTimeGraph(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    minDate: any,
    maxDate: any,
    column: any,
    modelFilters: any[],
    dateBucketType: string,
    aggregateConversations: boolean,
    selectedDateZoomIn: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/time?min_date=${minDate}&max_date=${maxDate}&column=${column}&date_bucket=${dateBucketType}&show_conversations=${aggregateConversations}&zoom_in=${selectedDateZoomIn}`,
      modelFilters,
      authHeaders(token),
    );
  },
  async getCategoryGraphs(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    column: any,
    categoryId: number,
    minDate: any,
    maxDate: any,
    modelFilters: any[],
    dateBucketType: string,
    selectedDateZoomIn: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/csat/category/${categoryId}?min_date=${minDate}&max_date=${maxDate}&column=${column}&date_bucket=${dateBucketType}&zoom_in=${selectedDateZoomIn}`,
      modelFilters,
      authHeaders(token),
    );
  },
  async getCategoryGraphsTime(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    categoryId: number,
    minDate: any,
    maxDate: any,
    column: any,
    modelFilters: any[],
    dateBucketType: string,
    aggregateConversations: boolean,
    selectedDateZoomIn: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/time/category/${categoryId}?min_date=${minDate}&max_date=${maxDate}&column=${column}&date_bucket=${dateBucketType}&show_conversations=${aggregateConversations}&zoom_in=${selectedDateZoomIn}`,
      modelFilters,
      authHeaders(token),
    );
  },

  async getEffectByCategory(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    categoryId: number,
    minDate: any,
    maxDate: any,
    column: any,
    modelFilters: any[],
    dateBucketType: string,
    date: string | null,
    selectedDateZoomIn: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/csat/category/${categoryId}/effect?min_date=${minDate}&max_date=${maxDate}&column=${column}&date_bucket=${dateBucketType}&date=${date}&zoom_in=${selectedDateZoomIn}`,
      modelFilters,
      authHeaders(token),
    );
  },
  async getEffectByCategoryTime(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    categoryId: number,
    minDate: any,
    maxDate: any,
    column: any,
    modelFilters: any[],
    dateBucketType: string,
    date: string | null,
    aggregateConversations: boolean,
    selectedDateZoomIn: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/time/category/${categoryId}/effect?min_date=${minDate}&max_date=${maxDate}&column=${column}&date_bucket=${dateBucketType}&date=${date}&show_conversations=${aggregateConversations}&zoom_in=${selectedDateZoomIn}`,
      modelFilters,
      authHeaders(token),
    );
  },
  async getEffectBySubCategory(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    column: any,
    subCategory: any,
    minDate: any,
    maxDate: any,
    modelFilters: any[],
    dateBucketType: string,
    date: string,
    selectedDateZoomIn: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/csat/subcategory/effect?min_date=${minDate}&max_date=${maxDate}&column=${column}&sub_category=${subCategory}&date_bucket=${dateBucketType}&date=${date}&zoom_in=${selectedDateZoomIn}`,
      modelFilters,
      authHeaders(token),
    );
  },
  async getEffectBySubCategoryTime(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    column: any,
    subCategory: any,
    minDate: any,
    maxDate: any,
    modelFilters: any[],
    dateBucketType: string,
    date: string | null,
    aggregateConversations: boolean,
    selectedDateZoomIn: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/time/subcategory/effect?min_date=${minDate}&max_date=${maxDate}&column=${column}&sub_category=${subCategory}&date_bucket=${dateBucketType}&date=${date}&show_conversations=${aggregateConversations}&zoom_in=${selectedDateZoomIn}`,
      modelFilters,
      authHeaders(token),
    );
  },
  async getColumnPreviewForDashboard(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    previewParameters: IPreviewParametersSubCategories,
    minDate: any,
    maxDate: any,
    column: any,
    modelFilters: any[],
    dateBucketType: string,
    selectedDateZoomIn: string,
  ) {
    let requestBody = {
      preview_parameters: previewParameters,
      model_filters: modelFilters,
    };
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/csat/subcategory/preview?min_date=${minDate}&max_date=${maxDate}&column=${column}&date_bucket=${dateBucketType}&zoom_in=${selectedDateZoomIn}`,
      requestBody,
      authHeaders(token),
    );
  },
  async getColumnPreviewForTimeDashboard(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    previewParameters: IPreviewParametersSubCategories,
    minDate: any,
    maxDate: any,
    column: any,
    modelFilters: any[],
    dateBucketType: string,
    selectedDateZoomIn: string,
  ) {
    let requestBody = {
      preview_parameters: previewParameters,
      model_filters: modelFilters,
    };
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/time/subcategory/preview?min_date=${minDate}&max_date=${maxDate}&column=${column}&date_bucket=${dateBucketType}&zoom_in=${selectedDateZoomIn}`,
      requestBody,
      authHeaders(token),
    );
  },
  async getCategoryPreviewForDashboard(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    previewParameters: IPreviewParametersCategoryDate,
    minDate: any,
    maxDate: any,
    column: any,
    modelFilters: any[],
    dateBucketType: string,
    selectedDateZoomIn: string,
  ) {
    let requestBody = {
      preview_parameters: previewParameters,
      model_filters: modelFilters,
    };
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/csat/categorys/preview?min_date=${minDate}&max_date=${maxDate}&column=${column}&date_bucket=${dateBucketType}&zoom_in=${selectedDateZoomIn}`,
      requestBody,
      authHeaders(token),
    );
  },
  async getCategoryPreviewForDashboardTime(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    previewParameters: IPreviewParametersCategoryDate,
    column: any,
    minDate: any,
    maxDate: any,
    modelFilters: any[],
    dateBucketType: string,
    selectedDateZoomIn: string,
  ) {
    let requestBody = {
      preview_parameters: previewParameters,
      model_filters: modelFilters,
    };
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/time/categorys/preview?min_date=${minDate}&max_date=${maxDate}&column=${column}&date_bucket=${dateBucketType}&zoom_in=${selectedDateZoomIn}`,
      requestBody,
      authHeaders(token),
    );
  },
  async getSubCategoryGraphs(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    column: any,
    subCategory: any,
    minDate: any,
    maxDate: any,
    modelFilters: any[],
    dateBucketType: string,
    selectedDateZoomIn: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/csat/subcategory?min_date=${minDate}&max_date=${maxDate}&column=${column}&sub_category=${subCategory}&date_bucket=${dateBucketType}&zoom_in=${selectedDateZoomIn}`,
      modelFilters,
      authHeaders(token),
    );
  },
  async getSubCategoryGraphsTime(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelId: number,
    column: any,
    subCategory: any,
    minDate: any,
    maxDate: any,
    modelFilters: any[],
    dateBucketType: string,
    aggregateConversations: boolean,
    selectedDateZoomIn: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/models/${modelId}/dashboard/time/subcategory?min_date=${minDate}&max_date=${maxDate}&column=${column}&sub_category=${subCategory}&date_bucket=${dateBucketType}&show_conversations=${aggregateConversations}&zoom_in=${selectedDateZoomIn}`,
      modelFilters,
      authHeaders(token),
    );
  },
  async setMetaData(token: string, workspaceId: number, datasetId: number, metaData: any) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/metadata`,
      metaData,
      authHeaders(token),
    );
  },
  async filterColumnValues(
    token: string,
    workspaceId: number,
    datasetId: number,
    columnId: number,
    modelFilters: any[] | null,
    search_string?: string // Optional search_string parameter
  ) {
    // Build the URL with the optional search_string parameter
    let url = `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/column_information?column_id=${columnId}`;
    
    // Append the search_string if it exists
    if (search_string) {
      url += `&search_string=${encodeURIComponent(search_string)}`; // Safely encode the search string
    }
  
    // Make the axios POST request
    return axios.post(
      url,
      modelFilters, // Pass modelFilters in the body
      authHeaders(token) // Authentication headers
    );
  },
  async triggerDatasetSetup(token: string, workspaceId: number, datasetId: number) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/setup`,
      {},
      authHeaders(token),
    );
  },
  async cloneDataset(token: string, datasetId: number, workspaceId: number) {
    const formData = new FormData();
    formData.append("dataset_id", datasetId.toString());
    formData.append("workspace_id", workspaceId.toString());

    return axios.post(`${apiUrl}/api/v1/utils/clonedataset`, formData, authHeaders(token));
  },

  async dumpAnalytics(token: string, datasetId: number) {
    return axios.post(
      `${apiUrl}/api/v1/utils/trigger-analytic-dump/${datasetId}`,
      {},
      authHeaders(token),
    );
  },

  async runBackupJob(token: string) {
    return axios.post(`${apiUrl}/api/v1/utils/runbackupjob`, {}, authHeaders(token));
  },
  async getFilterCount(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelFilters: any[] | null,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/filter/count`,
      modelFilters,
      authHeaders(token),
    );
  },
  async deployModel(token: string, workspaceId: number, modelId: number, deploy: boolean) {
    const formData = new FormData();
    formData.append("deploy", deploy.toString());

    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/deploy`,
      formData,
      authHeaders(token),
    );
  },
  async changeColor(token: string, workspaceId: number, modelId: number, color: string | null) {
    const formData = new FormData();
    if (color !== null) {
      formData.append("color", color.toString());
    } else {
      formData.append("color", "nocolor");
    }

    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/color`,
      formData,
      authHeaders(token),
    );
  },
  async changeGenerativeColor(
    token: string,
    workspaceId: number,
    modelId: number,
    color: string | null,
  ) {
    const formData = new FormData();
    if (color !== null) {
      formData.append("color", color.toString());
    } else {
      formData.append("color", "nocolor");
    }

    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}/generative_color`,
      formData,
      authHeaders(token),
    );
  },
  async getUserExampleCount(token: string, workspaceId: number, modelId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/count_user_examples`,
      authHeaders(token),
    );
  },

  async harmonize(token: string, datasetId: number) {
    return axios.post(
      `${apiUrl}/api/v1/utils/dataset/${datasetId}/assign_cluster_ids`,
      {},
      authHeaders(token),
    );
  },

  async startRefillSetup(token: string, datasetId: number) {
    const formData = new FormData();
    formData.append("dataset_id", datasetId.toString());

    return axios.post(`${apiUrl}/api/v1/utils/start-refill-setup`, formData, authHeaders(token));
  },

  async startPromptGeneration(token: string, modelId: number, overwritePrompts: boolean) {
    const formData = new FormData();
    formData.append("model_id", modelId.toString());
    formData.append("overwrite_prompts", overwritePrompts.toString());

    return axios.post(`${apiUrl}/api/v1/utils/prompt_generation`, formData, authHeaders(token));
  },

  async deletePromptResponses(token: string, modelId: number) {
    const formData = new FormData();
    formData.append("model_id", modelId.toString());

    return axios.post(
      `${apiUrl}/api/v1/utils/delete_prompt_responses`,
      formData,
      authHeaders(token),
    );
  },

  async exportExternalConversationLabelData(
    token: string,
    datasetId: number,
    daysBack: number,
    useCold: boolean,
  ) {
    const formData = new FormData();
    formData.append("dataset_id", datasetId.toString());
    formData.append("days_back", daysBack.toString());
    formData.append("use_cold", useCold.toString());
    return axios.post(
      `${apiUrl}/api/v1/utils/export_external_conversation_label_data`,
      formData,
      authHeaders(token),
    );
  },

  async startRemoveDuplicatePrompts(token: string, datasetId: number) {
    const formData = new FormData();
    formData.append("dataset_id", datasetId.toString());
    return axios.post(
      `${apiUrl}/api/v1/utils/remove_duplicate_prompts`,
      formData,
      authHeaders(token),
    );
  },

  async startRemoveDuplicateUtterances(token: string, datasetId: number) {
    const formData = new FormData();
    formData.append("dataset_id", datasetId.toString());
    return axios.post(
      `${apiUrl}/api/v1/utils/remove_duplicate_utterances`,
      formData,
      authHeaders(token),
    );
  },

  async startDataRetention(token: string) {
    const formData = new FormData();
    return axios.post(`${apiUrl}/api/v1/utils/start_data_retention`, formData, authHeaders(token));
  },

  async updateClusterName(
    token: string,
    datasetId: number,
    workspaceId: number,
    clusterType: string,
    oldClusterName: string,
    newClusterName: string,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/update_cluster_name?cluster_type=${clusterType}&old_cluster_name=${oldClusterName}&new_cluster_name=${newClusterName}`,
      {},
      authHeaders(token),
    );
  },
  async searchDataset(
    token: string,
    workspaceId: number,
    datasetId: number,
    searchString: string,
    includeColumns: string[],
    modelFilters: any,
    page: number,
    similarity: number,
    randomSearch: boolean = false,
    pageSize: number = 10,
  ) {
    const payload = {
      include_columns: includeColumns,
      filters: modelFilters,
    };

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/search?search_string=${searchString}&page=${page}&similarity=${similarity}&random_search=${randomSearch}&page_size=${pageSize}`,
      payload,
      authHeaders(token),
    );
  },
  async getSystemColumns(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/system/columns_metadata`,
      authHeaders(token),
    );
  },
  async getConversation(
    token: string,
    workspaceId: number,
    datasetId: number,
    conversationId: any,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/conversation/${conversationId}/messages`,
      authHeaders(token),
    );
  },
  async getDefaultPrompts(token: string, workspaceId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/default_prompts`,
      authHeaders(token),
    );
  },

  async getRandomConversations(token: string, workspaceId: number, modelId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}/random_conversations`,
      authHeaders(token),
    );
  },

  async getRandomConversationsFromDataset(
    token: string,
    workspaceId: number,
    datasetId: number,
    filters: any,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/random_conversations`,
      filters,
      authHeaders(token),
    );
  },

  async getConversations(token: string, workspaceId: number, modelId: number, page: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}/all_conversations?page=${page}`,
      authHeaders(token),
    );
  },

  async getPromptResponses(token: string, workspaceId: number, modelId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}/prompt_responses`,
      authHeaders(token),
    );
  },

  async getPromptResponsesFromClassification(
    token: string,
    workspaceId: number,
    modelId: number,
    body: any,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/prompt_responses`,
      body,
      authHeaders(token),
    );
  },

  async streamConversationResponse(
    token: string,
    model_id: number | null,
    workspaceId: number,
    datasetId: number,
    conversationId: any,
    previousMessages: any = [],
    generateNew: boolean = false,
  ) {
    const url = `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/conversation/${conversationId}/summary`;
    const headers = { ...authHeaders(token).headers };
    headers["Content-Type"] = "application/json";

    const body: string = JSON.stringify({
      previous_messages: previousMessages,
      generate_new: generateNew,
      model_id: model_id,
    });

    // Make the fetch request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    });

    // Check if the response status code is not 200 (OK)
    if (!response.ok) {
      // Throw an error with the status code and status text
      console.log(body);
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    // Check if the response has a body before returning
    if (!response.body) {
      throw new Error("No ReadableStream received");
    }

    // Return the reader for the body stream
    return response.body.getReader();
  },

  async saveConversationSummary(
    token: string,
    workspaceId: number,
    datasetId: number,
    conversationId: any,
    summary: string,
  ) {
    const payload = { summary: summary };
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/conversation/${conversationId}/save_summary`,
      payload,
      authHeaders(token),
    );
  },
  async getAudioFile(token: string, workspaceId: number, datasetId: number, audioFileName: string) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/audiofile?audio_filename=${audioFileName}`,
      authHeadersBlob(token),
    );
  },
  async getConversationIdFromItemId(
    token: string,
    modelId: number,
    storeId: string,
    itemId: string,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/models/${modelId}/items/conversation_id?item_id=${itemId}&store_id=${storeId}`,
      authHeaders(token),
    );
  },
  async getItemFromModelById(token: string, modelId: number, storeId: string, itemId: string) {
    return axios.get(
      `${apiUrl}/api/v1/models/${modelId}/items/item/${itemId}?store_id=${storeId}`,
      authHeaders(token),
    );
  },
  async getItemFromDatasetById(
    token: string,
    workspaceId: number,
    datasetId: number,
    itemId: string,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/items/${itemId}`,
      authHeaders(token),
    );
  },

  async updateItemFromModelById(
    token: string,
    modelId: number,
    storeId: string,
    itemId: string,
    editedText: string,
  ) {
    const formData = new FormData();
    formData.append("edited_text", editedText);

    return axios.put(
      `${apiUrl}/api/v1/models/${modelId}/items/item/edit/${itemId}?store_id=${storeId}`,
      formData,
      authHeaders(token),
    );
  },

  async updateItemFromDatasetById(
    token: string,
    workspaceId: number,
    datasetId: number,
    itemId: string,
    editedText: string,
  ) {
    const formData = new FormData();
    formData.append("edited_text", editedText);

    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/items/${itemId}`,
      formData,
      authHeaders(token),
    );
  },
  async getSearchSettings(token: string, workspaceId: number, datasetId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/search_settings`,
      authHeaders(token),
    );
  },

  async getInferencePrompts(token: string, workspaceId: number, datasetId: number) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/inference_prompts`,
      authHeaders(token),
    );
  },

  async createInferencePrompt(
    token: string,
    workspaceId: number,
    datasetId: number,
    inferencePrompt: IInferencePromptCreate,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/inference_prompts`,
      inferencePrompt,
      authHeaders(token),
    );
  },

  async updateInferencePrompt(
    token: string,
    workspaceId: number,
    datasetId: number,
    inferencePromptId: number,
    inferencePrompt: IInferencePromptUpdate,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/inference_prompts/${inferencePromptId}`,
      inferencePrompt,
      authHeaders(token),
    );
  },

  async deleteInferencePrompt(
    token: string,
    workspaceId: number,
    datasetId: number,
    inferencePromptId: any,
  ) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/inference_prompts/${inferencePromptId}`,
      authHeaders(token),
    );
  },
  async saveSearchSettings(
    token: string,
    workspaceId: number,
    datasetId: number,
    searchSettings: any,
    name: string,
  ) {
    const payload = {
      search_settings: searchSettings, // Spread the searchSettings object
      name: name, // Include the name
    };

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/search_settings`,
      payload,
      authHeaders(token),
    );
  },
  async removeSearchSettings(
    token: string,
    workspaceId: number,
    datasetId: number,
    searchSettingsId: number,
  ) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/search_settings/${searchSettingsId}`,
      authHeaders(token),
    );
  },
  async changeSearchSettings(
    token: string,
    workspaceId: number,
    datasetId: number,
    searchSettingsId: number,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/search_settings/${searchSettingsId}`,
      {},
      authHeaders(token),
    );
  },
  async getLabeledExamples(
    token: string,
    workspaceId: number,
    modelId: number,
    labelId: any,
    page: number,
  ) {
    if (labelId === null) {
      return axios.get(
        `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/labeled_examples?page=${page}`,
        authHeaders(token),
      );
    } else {
      return axios.get(
        `${apiUrl}/api/v1/workspaces/${workspaceId}/models/${modelId}/labeled_examples?page=${page}&label_id=${labelId}`,
        authHeaders(token),
      );
    }
  },

  async getAllMainClusters(
    token: string,
    workspaceId: number,
    datasetId: number,
    modelFilters: any,
    hasSpeaker: boolean,
  ) {
    const payload = {
      filters: modelFilters,
      has_speaker: hasSpeaker,
    };

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/main_clusters?has_speaker=${hasSpeaker}`,
      modelFilters,
      authHeaders(token),
    );
  },

  async getSubClusters(
    token: string,
    workspaceId: number,
    datasetId: number,
    mainClusterId: number,
  ) {
    return axios.get(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/main_clusters/${mainClusterId}/sub_clusters`,
      authHeaders(token),
    );
  },
  async getSubClustersForManyMain(
    token: string,
    workspaceId: number,
    datasetId: number,
    mainClusterIds: Array<number>,
  ) {
    const payload = {
      main_cluster_ids: mainClusterIds,
    };
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/datasets/${datasetId}/main_clusters/get_sub_clusters_many`,
      payload,
      authHeaders(token),
    );
  },

  async saveGenerativePrompt(
    token: string,
    modelId: number,
    workspaceId: number,
    generativePrompt: IGenerativeModelPromptUpdate,
  ) {
    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}/generative_prompts`,
      generativePrompt,
      authHeaders(token),
    );
  },
  async runGenerativePrompt(
    token: string,
    workspaceId: number,
    modelId: number,
    overWritePrompts: boolean,
  ) {
    const formData = new FormData();
    formData.append("overwrite_prompts", overWritePrompts.toString());

    return axios.post(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}/run_generative_prompt`,
      formData,
      authHeaders(token),
    );
  },
  async undeployGenerativeModel(token: string, workspaceId: number, modelId: number) {
    return axios.put(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}/undeploy`,
      {},
      authHeaders(token),
    );
  },
  async deletePromptGenerations(token: string, workspaceId: number, modelId: number) {
    return axios.delete(
      `${apiUrl}/api/v1/workspaces/${workspaceId}/generative_models/${modelId}/delete_prompt_generations`,
      authHeaders(token),
    );
  },
};
